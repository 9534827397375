import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { mapEdgesToNodes, toPlainText } from "../helpers";

import {
  GraphQLError,
  SEO,
  Container,
  PortableText,
} from "../components/elements";
import LayoutContainer from "../containers";

import { ResponsiveTitle1 } from "../components/styled";
import BlogPostPreviewGrid from "../components/BlogPostPreviewGrid";
import CuratedLinks from "../components/CuratedLinks/CuratedLinks";

export const query = graphql`
  query AuthorPageQuery($author: String) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        authors: { elemMatch: { author: { name: { in: [$author] } } } }
        isBackgroundPost: { ne: true }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          tags {
            tag {
              title
            }
          }
        }
      }
    }
    details: sanityAuthor(name: { eq: $author }) {
      _rawBio(resolveReferences: { maxDepth: 5 })
      keywords
      curatedLinks {
        _key
        reference {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const AuthorPage = (props) => {
  const { data, errors, pageContext } = props;
  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLError errors={errors} />
      </LayoutContainer>
    );
  }

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts);
  const { _rawBio, keywords, curatedLinks } = data && data.details;
  const { author } = pageContext;

  return (
    <LayoutContainer>
      <SEO
        title={author}
        keywords={keywords}
        description={toPlainText(_rawBio)}
      />
      <Container>
        <ResponsiveTitle1>{author}</ResponsiveTitle1>
        {_rawBio && <PortableText blocks={_rawBio} />}
        <Divider />
        {curatedLinks && curatedLinks.length > 0 && (
          <>
            <CuratedLinks links={curatedLinks} />
            <Divider />
          </>
        )}
        {postNodes && postNodes.length > 0 && (
          <BlogPostPreviewGrid nodes={postNodes} />
        )}
      </Container>
    </LayoutContainer>
  );
};

const Divider = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

export default AuthorPage;
